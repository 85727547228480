<template>
  <div>
    <portal to="page-top-title">
      <span v-if="isProfile">Profile</span>
      <span v-else>User #{{ id }}</span>
    </portal>
    <portal to="page-top-right">
      <template v-if="user.metadata.editable.this">
        <btn :route="isProfile ? { name: 'profile-edit' } : { name: 'users-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
      </template>
    </portal>

    <box-single :url="dataUrl" data-prop="user" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ user.name }}</column-info>
          <column-info title="Email">{{ user.email }}</column-info>
          <column-info title="Created At">{{ user.created_at }}</column-info>
          <column-info title="Updated At">{{ user.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Active">
            <badge-active :value="user.active"/>
          </column-info>
          <column-info title="2FA Enabled">
            <badge-active :value="user.tfa_enabled"/>
          </column-info>
          <column-info title="Unblock At">
            <nullable :value="user.unblock_on"/>
          </column-info>
          <column-info title="Pswd Updated At">
            <nullable :value="user.pswd_updated_at"/>
          </column-info>
        </row>
        <row>
          <column-info title="Roles" v-if="user.roles">
            <template v-for="(role, r) in user.roles">
              <span :key="`user-role-${r}-comma`" v-if="r !== 0">, </span>
              <code :key="`user-role-${r}`">{{ $helpers.Str.titleCase(role.name) }}</code>
            </template>
          </column-info>
        </row>
        <row>
          <column-info title="Company" v-if="user.company">
            <router-link :to="{ name: 'companies-single', params: { id: user.company.id } }">{{ user.company.name }}</router-link>
          </column-info>
          <column-info title="Owner">
            <badge-active :value="user.owner"/>
          </column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Nullable from '@/views/components/simple/Nullable'
import Btn from '@/views/components/simple/Btn'
import user from '@/modules/strubs/user'
import { mapGetters } from 'vuex'

export default {
  name: 'UsersSingle',
  metaInfo () {
    return { title: this.isProfile ? 'Profile' : `User #${this.id}` }
  },
  components: {
    BoxSingle,
    BadgeActive,
    Nullable,
    Btn
  },
  computed: {
    ...mapGetters({
      userAuth: 'auth/user'
    }),
    isProfile () { return this.$route.name === 'profile' },
    id () { return this.isProfile ? this.userAuth.id : this.$route.params.id },
    dataUrl () { return this.isProfile ? '/profile' : `/users/${this.id}` }
  },
  data () {
    return {
      user: user
    }
  }
}
</script>
